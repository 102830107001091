<script setup lang="ts">
export type Props = {
    open?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    open: false,
})

export type Emits = {
    (event: 'update:open', value: boolean): void
}

defineEmits<Emits>()

watch(
    () => props.open,
    (open) => {
        const action = open ? 'add' : 'remove'

        document.documentElement.classList[action]('modal--open')
    },
)
</script>

<template>
    <Teleport to="body">
        <dialog
            :open="open"
            class="modal"
        >
            <div class="modal__backdrop"></div>
            <div class="modal__actions">
                <Icon
                    @click="$emit('update:open', false)"
                    class="modal__close"
                    name="close-32x"
                    fluid
                />
            </div>

            <div class="modal__overflow">
                <div class="modal__content">
                    <slot />
                </div>
            </div>
        </dialog>
    </Teleport>
</template>

<style lang="scss">
#app {
    transition: all 0.4s ease;
}
html.modal--open {
    // scrollbar-gutter: unset;

    #app {
        filter: blur(20px);
    }
}

.modal {
    @include v(
        (
            icon-close-size: 32px,
        )
    );

    position: fixed;
    z-index: 11234;
    inset: 0;
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // filter: blur(1rem);
    // transition: all 0.4s ease;
    transition: all 0.4s ease;
    // transition-delay: 0.4s;
    pointer-events: none;
    opacity: 0;
    background-color: transparent;

    &[open] {
        opacity: 1;
        // filter: blur(0rem);
        pointer-events: inherit;
    }

    &__backdrop {
        background: rgba(218, 237, 239, 0.53);
        // backdrop-filter: blur(20px);
        z-index: -1;
        position: fixed;
        inset: 0;
    }

    &__overflow {
        overflow-y: scroll;
        scrollbar-gutter: stable;
        scrollbar-width: none;
        height: 100%;
        overscroll-behavior: contain;
        // background-color: v(petrol-20);
        // background-color: v(petrol-20);
    }

    &__actions {
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
        line-height: 0;
        height: 0;

        padding: 0 v(s-16);
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-items: flex-end;
        justify-content: flex-end;
        max-width: calc(768px);
        margin: auto;
    }

    &__close {
        width: v(icon-close-size);
        height: v(icon-close-size);
        cursor: pointer;
    }

    &__content {
        max-width: 768px;
        margin: auto;
        height: 100%;
    }
}
</style>
